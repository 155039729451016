.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
  
    svg {
      width: 100%;
      height: auto;
      bottom: 0;
      transform: rotateZ(30deg) !important;
    }
  
    .solid-logo {
      position: absolute;
      top: 20%;
      right: auto;
      bottom: auto;
     // left: -47%;
      margin: auto;
      width: 100%;
      
    }
  }
  
  .svg-container {
    stroke: #ffd700;
    stroke-width: 10;
  }

  
  @media only screen and (max-width: 600px) {
    .logo-container{
      bottom: 70%;
    }
    .solid-logo {
     
      left: -47%;
      padding-bottom: 110%;
      padding-left:70%;
   
      
      
    }
  
  }
  