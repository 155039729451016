.contact-form{
    width: 55vw;
    margin-top: 20px;

    ul{
        padding: 0;
        margin: 0;

        li{
            padding: 0;
            margin: 0;
            list-style:none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half{
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child{
                margin-left: 0;
            }

        }
    }
    input[type='text'],
    input[type='email']{
        width: 100%;
        border: 0;
        background:#115173;
        height: 50px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;


    }

    textarea{
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color:#ffffff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;

    }

    .flat-button{
        background-color: #6e6010;
        color:#ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        
        &:hover{
            background: #ffd700;
            color:#333;
        }
    
    }
}


.popup{
    position:absolute;
    width: 800px;
    background-color:#115173 ;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border: 3px solid black;
    padding: 10px;
    text-align: center;
    color: #ffd700;
    font-family: Coolvetica;
    font-size: 35px;
    margin-bottom: 50%;
    left: 50%;
   right: 50%;
   bottom: 2%;    
    -webkit-animation: fadeinout 4s linear forwards;
   animation: fadeinout 4s linear forwards;

}
@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
@media only screen and (max-width: 600px) {
    .contact-form{
        width: 70vw;
    margin-top: 20px;
    }
    .popup{
        position:absolute;
    width: 70%;
    background-color:#115173 ;
 
   
   left: 5%;
    border: 3px solid black;
    padding: 10px;
    text-align: center;
    color: #ffd700;
    font-family: Coolvetica;
    font-size: 35px;
    margin-bottom: 50%;
    
    right: 100%;
   bottom: 2%;    
   -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
    }
}



